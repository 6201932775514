@import "../../../assets/css/variables";

.page-lead {
    font-weight: 400;
    margin: 0 auto;
    padding: 3.5rem 0 1.5rem 0;
    text-align: center;
    max-width: $page-width-md;

    .page-lead-text-script {
        color: $brand-secondary;
        font-family: "Merriweather", serif;
        font-style: italic;
        font-weight: 600;
        line-height: normal;
    }
}

.page-lead-subtext {
    font-size: 1.125rem;
    line-height: 2rem;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: $page-width-md;

    &.danger {
        color: $brand-danger;
        font-weight: 700;

        a {
            color: $brand-danger;
        }
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.page-lead-separator {
    background: $brand-secondary;
    margin: 2.5rem auto 3rem auto;
    width: 10rem;
    height: .125rem;
}