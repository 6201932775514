@import "../../../assets/css/variables";

.page-card {
    &.border-none {
        border: none;
    }
}

.page-card-separator {
    background: $brand-secondary;
    margin: 2.5rem auto;
    height: .25rem;
}


.page-card-title-divider {
    color: #1a2630;
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 8rem;
   
}

.page-card-text-container {
    min-height: 13rem;
    .btn {
        position: absolute;
        bottom: 1rem;
    }
}

.page-card-graphic-container {
   max-width: 18.75rem;
    
}

.img-right {
    img {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
};
.img-left {
    img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    } 
    &.img-small {
        img {
            height: 8rem;
        } 
    }
}
.img-top {
    img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    } 
}

.services-card-container {
    display: flex;
    justify-content: center;
}

.outer-card {
    border-color: $brand-tertiary;
    max-width: 250px;
    max-height: 215px;

}

.inner-card {
    margin: -6px -6px 6px 6px;
    border-color: $brand-primary;
    background-color: transparent;
    max-width: 250px;
    max-height: 215px;

    img {
        height: 7rem;
        width: 7rem;
    }

    .services-card-body {
        width: 115%;
        align-items: center;

        .col {
            padding: 0 0.375rem
        }
    }

    .services-card-button {
        text-align: end
    }
};

.container-fluid {
    &.bg-color {
        background-color: $brand-neutral;
    }

}

    
