@import "../../../assets/css/variables";

.toast-success {
    background-color: $brand-primary;
    & .toast-body {
        color: $brand-white;
    }
}

.toast-fail {
    background-color: $brand-danger;
    & .toast-body {
        color: $brand-white;
    }
}

.toast-warning {
    background-color: $brand-warning;
}

.toast-info {
    background-color: $brand-secondary;
    & .toast-body {
        color: $brand-white;
    }
}
