// ---------------------------------------------------------
// Four Rivers Vet

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap");

@import "../assets/css/variables";
@import "node_modules/bootstrap/scss/bootstrap";

// ---------------------------------------------------------
// Normalization overrides for "sticky footers"
#root {
    display: flex;
    flex-direction: column;
    min-height: $page-height;
}

body {
    min-height: $page-height !important;
    color: $brand-body-color;
}

// ---------------------------------------------------------
// Bootstrap overrides
.btn {
    --bs-btn-padding-x: 1rem;
}

.call-to-action-button {
    --bs-btn-bg: $brand-secondary;
    --bs-btn-color: $brand-white;
    --bs-btn-border-color: $brand-secondary;
    //--bs-btn-font-weight: 400;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: $brand-secondary;
    --bs-btn-hover-color: $brand-secondary;
    --bs-btn-active-bg: $brand-secondary;
    --bs-btn-active-border-color: $brand-secondary;
    --bs-btn-disabled-color: $brand-secondary;
    --bs-btn-disabled-border-color: $brand-secondary;

    @media screen and (min-width: $page-width-lg) {
        //--bs-btn-font-size: 1.25rem;
        //--bs-btn-font-weight: 500;
    }
}

.call-to-action-button-outline {
    --bs-btn-color: $brand-secondary !important;
    --bs-btn-border-color: $brand-secondary !important;
    --bs-btn-font-weight: 400 !important;
    --bs-btn-hover-bg: $brand-secondary !important;
    --bs-btn-hover-border-color: $brand-secondary !important;
    --bs-btn-active-bg: $brand-secondary !important;
    --bs-btn-active-border-color: $brand-secondary !important;
    --bs-btn-disabled-color: $brand-secondary !important;
    --bs-btn-disabled-border-color: $brand-secondary !important;

    @media screen and (min-width: $page-width-lg) {
        --bs-btn-font-size: 1.25rem !important;
        --bs-btn-font-weight: 500 !important;
    }
}

.form-control {
    padding: 0.75rem 0.75rem;
    // &:focus {
    //     box-shadow: $brand-box-shadow;
    //     border-color: $brand-shadow;
    // }
}

.toast-body {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 1rem;

    & i {
        font-size: 1.5rem;
        margin-right: .75rem;
    }
}

// ---------------------------------------------------------
// Global Custom CSS
.max-width-lg {
    max-width: $page-width-lg;
}
.max-width-xl {
    max-width: $page-width-xl;
}
.max-width-md {
    max-width: $page-width-md;
}

.max-width-sm {
    max-width: $page-width-sm;
}