@import "../../../assets/css/variables";


.every-midpagehero-container {
    background-color: $brand-primary;
}

.every-midpagehero-img-container {
    display: flex;
    justify-content: center;

    img {
        max-height: 7rem;
        margin-bottom: 1rem;

        @media screen and (min-width: $page-width-md) {
            max-height: 10rem;
            margin-bottom: 0;
            justify-content: end;
        }
    }
    
}