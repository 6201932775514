@import "./variables";

.services-landing {
    h3 {
        color: $brand-secondary;
    }
}

.services-icon {
    height: 0;
    width: 0;
    
    @media screen and (min-width: 18.75rem) {
        height: 3.281rem;
        width: 3.281rem;
    }

    @media screen and (min-width: $page-width-md) {
        height: 4.281rem;
        width: 4.281rem; 
    }
    
}

.services-card-column {
    display: flex;
    justify-content: center;
}

.services-accordion {
    width: 15rem;
}

.services-card {
    margin-left: 1.25rem;
    border: 0;
    align-items: center;
    background-color: transparent;
}

.services-card-body {
    h5 {
        color: $brand-primary;
    }
    word-break: break-word;
    @media screen and (min-width: $page-width-sm) {
        word-break: normal;
    }
}

.services-card-body-alt {
    min-height: 6rem;
    min-width: 6rem;
    text-align: center;
    h5 {
        color: $brand-primary;
    }
    word-break: break-word;
    @media screen and (min-width: $page-width-sm) {
        word-break: normal;
    }
}

.services-accordion-header {
    button {
        padding: 0;
        text-align: center;
    }
    .accordion-button {
        justify-content: center;
    }
    .accordion-button::after {
        margin-left: .5rem;
        margin-right: .5rem;
        margin-top: 32%;

        @media screen and (min-width: $page-width-sm) {
            margin-top: 30%;
        }

        @media screen and (min-width: $page-width-md) {
            margin-top: 35%;
        }
        
        @media screen and (min-width: $page-width-lg) {
            margin-top: 40%;
        }
        @media screen and (min-width: $page-width-xl) {
            margin-top: 34%;
        }
      }

      &.no-arrow {
        .button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
            pointer-events: none;
            cursor: default;
        }
        .accordion-button:not(.collapsed) {
            color: unset;
            background-color: transparent;
            box-shadow: unset;
            pointer-events: none;
            cursor: default;
        }
        .accordion-button::after {
            width: 0;
            height: 0;
        }
      }
}