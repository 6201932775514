@import "../../../assets/css/variables";
@import "../../../assets/css/base";

.every-circle-link {
    cursor: pointer;
}

.every-circle-img-container {
    @extend .base-image-rounded;
    margin-bottom: .5rem;
}

.every-circle-img {
    @extend .base-background-image;
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    display: inline-block;
    -webkit-mask-size: 70%;
    mask-size: 70%;
}

.every-circle-modal-title-separator {
    background: $brand-secondary;
    margin: .5rem 0;
    width: 5rem;
    height: .3rem;

}

.every-circle-name-link {
    color: $brand-primary;

    &:hover {
        text-decoration: underline;
    }
}