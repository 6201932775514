@import "../../assets/css/variables";


// ---------------------------------------------------------
// Bootstrap override to force nav-bar to right side
.navbar-collapse {
   flex-grow: 0;
}

.navbar-nav {
    --bs-nav-link-color: #ffffff;
}

// ---------------------------------------------------------
// Custom CSS
.header-navbar {
    background-color: $brand-primary;
}

.header-logo {
    width: 18rem;
}

.header-contact-info {
    background-color: $brand-quaternary;
    color: $brand-body-color;
    padding: .5rem 0;
    text-align: center;

    .row {
        --bs-gutter-x: 0;
    }

    .col-sm-6 {
        text-align: center;
    }

    h6 {
        margin: 0;
    }
    img {
        height: 1rem;
        width: 1rem;
    }

    a {
        text-decoration: none;
        color: $brand-body-color;
        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: $page-width-sm) {
        .col-sm-6 {
            max-width: 48%;
        }
        .col-sm-6:first-of-type {
            text-align: end;
            margin-right: .5rem;
        }    
        .col-sm-6:last-of-type {
            text-align: start;
            margin-left: .5rem;
        }      
    }
}

.header-button {
    @media screen and (min-width: $page-width-lg) {
        &.btn {
            margin: 0 0.5rem;
        }

    }
}