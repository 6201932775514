@import "../../../assets/css/variables";

.every-faq-accordion {
  .accordion-item {
    border: 0;
}

.accordion-button {
    padding: .5rem;
    color: $brand-primary;
    &:hover {
        color: $brand-tertiary;
    }
}

.accordion-body {
    padding-top: 0;
    color: #000000;
}

.accordion-button:not(.collapsed) {
    color: $brand-primary;
    background-color: transparent;
    box-shadow: none;
}

.accordion-button:focus {
    border: 0;
    color: $brand-tertiary;
    box-shadow: none;
}

.accordion-button::before {
    background-image: url("../../../assets/images/icons/plus-sign.svg");
    transform: scale(.7) !important;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-right: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }
  .accordion-button:not(.collapsed)::before {
    background-image: url("../../../assets/images/icons/minus-sign.svg");
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-right: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }

  .accordion-button::after {
    display: none;
  }
  .accordion-button:not(.collapsed)::after {
    display: none;
  }
}

