@import "../../../assets/css/variables";

// ---------------------------------------------------------
// Custom CSS
.main-pagecard-container {
    background-color: $brand-primary;
}
.main-hero-outer-container {
    background-color: $brand-primary;
}
.main-secondary-hero {
    display: inline-block;
    height: 20rem;
    background: url("../../../assets/images/dog_cat_cursive_outline.svg") repeat 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.main-hero-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $brand-primary;
    color: $brand-white;
    .row {
        justify-content: center;
        align-items: center;
    }
    .col-lg-3 {
        display: flex;
        justify-content: center;
        @media screen and (min-width: $page-width-lg) {
            width: 24%;
            
        }
    }
    .col-lg-6 {
        @media screen and (min-width: $page-width-lg) {
            width: 52%;  
        }
    }
    .col-lg-6, .col-lg-3 {
        @media screen and (min-width: $page-width-lg) {
            padding: 0;
            
        }
    }

    .btn, .btn-primary {
        margin-top: 1.5rem;
    }

    h5 {
        color: $brand-quaternary;
    }
    

    img {
        max-width: 100%;
        &.hero-sm {  
            display: none;
            @media screen and (min-width: $page-width-sm) {
                display: inline;
                max-width: 40%;
                margin-left: 4rem;
                
            }
            @media screen and (min-width: $page-width-md) {
                margin-left: 10rem;
                max-width: 45%;
                
            }
            @media screen and (min-width: $page-width-lg) {
                display: none;
                
            }
        }

        &.hero-lg {
            display: none;
            @media screen and (min-width: $page-width-lg) {
                display: block
                
            }
        }
        
    }

    @media screen and (min-width: $page-width-sm) { 
        .col-md-6:last-of-type {
            text-align: center;
        }      
    }
}