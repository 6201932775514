// BASE SCSS USED ACROSS MULTIPLE COMPONENTS
// more info: https://sass-lang.com/documentation/at-rules/extend#extends-or-mixins

/* ===============================================================
 * SCSS GLOBAL EXTENDS
 * The @extend directive lets you share a set of CSS properties from one selector to another.
 * The @extend directive is useful if you have almost identically styled elements that only differ in some small details.
 * Called with @extend
// --------------------------------------------------------- */

.base-image-rounded {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.25rem;
}

.base-background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* ===============================================================
 * SCSS GLOBAL MIXINS
 * The @mixin directive lets you create CSS code that is to be reused throughout the website.
 * @mixin takes arguments that allow small differences in styling
 * Called with @include
// --------------------------------------------------------- */
